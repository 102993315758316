import React from "react";
import Layout from "../components/layout";
import Sidebar from "../components/organisms/Sidebar/sidebar.components";
import Nav from "../components/organisms/Nav/nav.components";
import Container from "../components/organisms/Container/container.components";
import SEO from "../components/seo";

const SecondPage: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Layout>
      <SEO
        title="Agende um teste"
        description={
          "Entenda como a nossa solução pode ajudar a sua logística! Preencha nosso formulário e em breve entraremos em contato para te explicar melhor nossa solução."
        }
      />
      <Sidebar open={open} toggle={toggle} />
      <Nav toggle={toggle} open={false} />
      <Container />
    </Layout>
  );
};

export default SecondPage;
