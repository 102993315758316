import styled from "styled-components";
import { FormControlLabel, Radio } from "@mui/material";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0.6rem;
  justify-content: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem;

  @media screen and (min-width: 500px) {
    padding: 2.3rem;
  }
`;

export const Field = styled.fieldset`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 8px;
  padding: 10px 0px;
  border: 0;
  outline: 0;

  @media screen and (min-width: 500px) {
    padding: 2.3rem;
    flex-direction: row;
  }
`;

export const FieldRow = styled.fieldset`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px 0px;
  border: 0;
  outline: 0;
`;

export const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  width: 100%;

  @media screen and (min-width: 300px) {
    grid-template-columns: 1fr 4fr;
  }

  @media screen and (min-width: 400px) {
    grid-template-columns: 1fr 5fr;
  }

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 6fr;
  }

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 7fr;
  }
`;

export const Label = styled.span`
  display: inline-block;
  font-size: 0.9rem;

  :after {
    content: " *";
    color: red;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 10px;
  margin-bottom: 1.3rem;
  gap: 10px;

  @media screen and (min-width: 470px) {
    gap: 6px;
    flex-flow: row;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiTypography-root {
    font-family: "EuropaBold", sans-serif !important;
    letter-spacing: 0;
  }
`;

export const StyledRadio = styled(Radio)`
  color: #e6e9ea !important;

  &.Mui-checked {
    color: #6ed796 !important;
  }
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 0.25rem;
  border: 0.0625rem solid #e6e9ea;
  outline: 0;
  height: 3rem;
  text-indent: 10px;
  font-size: 0.9rem;
  margin-top: 7px;

  &:hover,
  &:focus,
  &:active {
    border: 0.0625rem solid #6ed796;
    outline: 0;
  }

  @media screen and (min-width: 300px) {
    font-size: 1rem;
  }
`;
export const Select = styled.select`
  width: 100%;
  border-radius: 0.25rem;
  border: 0.0625rem solid #e6e9ea;
  outline: 0;
  height: 3rem;
  text-indent: 10px;
  font-size: 0.9rem;
  margin-top: 7px;

  &:hover,
  &:focus,
  &:active {
    border: 0.0625rem solid #6ed796;
    outline: 0;
  }

  @media screen and (min-width: 300px) {
    font-size: 1rem;
  }
`;

export const TextArea = styled.textarea`
  height: 100%;
  border-radius: 0.25rem;
  border: 0.0625rem solid #e6e9ea;
  outline: 0;
  padding: 10px;
  font-size: 0.9rem;
  resize: none;

  &:hover,
  &:focus,
  &:active {
    border: 0.0625rem solid #6ed796;
    outline: 0;
  }

  @media screen and (min-width: 300px) {
    font-size: 1rem;
  }
`;

export const Button = styled.button`
  background-color: #6ed796;
  color: #000000;
  border-radius: 2px;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 1.0625rem;
  padding: 0.675rem 2rem;
  cursor: pointer;
`;

export const Error = styled.span`
  font-size: 12px;
  color: red;
  margin-top: 3.5px;
`;

export const Success = styled.span`
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 10px;
  font-size: 16px;
`;
