import React, { useState } from "react";
import {
  Wrapper,
  FieldRow,
  Label,
  Input,
  Button,
  Select,
  Error,
  Success,
} from "./form.styles";
import * as yup from "yup";
import { useFormik } from "formik";

const segments = [
  { label: "Escolha uma opção", value: "0" },
  { label: "Autopeças", value: "Autopeças" },
  { label: "Food Service", value: "Food Service" },
  { label: "E-commerce", value: "E-commerce" },
  { label: "Farmacêutica", value: "Farmacêutica" },
  { label: "Varejo", value: "Varejo" },
  { label: "Outros", value: "Outros" },
];
const quantity = [
  { label: "Escolha uma opção", value: "0" },
  { label: "Abaixo de 100", value: "Abaixo de 100" },
  { label: "100 - 500", value: "100 - 500" },
  { label: "501 - 2500", value: "501 - 2500" },
  { label: "2501 - 10000", value: "2501 - 10000" },
  { label: "Acima de 10000", value: "Acima de 10000" },
];

const initialValues = {
  cf_nome_lead: "",
  email: "",
  cf_nome_empresa: "",
  cf_telefone_contato: "",
  cf_quantidade: "",
  cf_industria: "",
};
const FormRDStation: React.FC = () => {
  const [status, setStatus] = useState<string>("");
  const [show, setShow] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      cf_nome_lead: yup.string().required("Por favor, preencha esse campo."),
      email: yup
        .string()
        .email("Por favor, digite um endereço de e-mail válido.")
        .required("Por favor, preencha esse campo."),
      cf_nome_empresa: yup.string().required("Por favor, preencha esse campo."),
      cf_telefone_contato: yup
        .string()
        .required("Por favor, preencha esse campo."),
      cf_quantidade: yup.string().required("Por favor, escolha uma opção."),
      cf_industria: yup.string().required("Por favor, escolha uma opção."),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(JSON.stringify(values, null, 2));
      resetForm();
      setShow(!show);
      setStatus("success");
    },
  });

  return (
    <>
      <Wrapper>
        <form name="agende-um-teste" onSubmit={formik.handleSubmit}>
          <FieldRow>
            <Label>Nome completo</Label>
            <Input
              type="text"
              name="cf_nome_lead"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cf_nome_lead}
            />
            {formik.touched.cf_nome_lead && formik.errors.cf_nome_lead ? (
              <Error>{formik.errors.cf_nome_lead}</Error>
            ) : null}
          </FieldRow>
          <FieldRow>
            <Label>Nome da empresa</Label>
            <Input
              type="text"
              name="cf_nome_empresa"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cf_nome_empresa}
            />
            {formik.touched.cf_nome_empresa && formik.errors.cf_nome_empresa ? (
              <Error>{formik.errors.cf_nome_empresa}</Error>
            ) : null}
          </FieldRow>
          <FieldRow>
            <Label>E-mail</Label>
            <Input
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <Error>{formik.errors.email}</Error>
            ) : null}
          </FieldRow>
          <FieldRow>
            <Label>Telefone</Label>
            <Input
              type="phone"
              name="cf_telefone_contato"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cf_telefone_contato}
            />
            {formik.touched.cf_telefone_contato &&
            formik.errors.cf_telefone_contato ? (
              <Error>{formik.errors.cf_telefone_contato}</Error>
            ) : null}
          </FieldRow>
          <FieldRow>
            <Label>Segmento: </Label>
            <Select
              name="cf_industria"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cf_industria}>
              {segments.map((op) => {
                return (
                  <option key={op.value} value={op.value}>
                    {op.label}
                  </option>
                );
              })}
            </Select>
            {formik.touched.cf_industria && formik.errors.cf_industria ? (
              <Error>{formik.errors.cf_industria}</Error>
            ) : null}
          </FieldRow>
          <FieldRow>
            <Label>Quantidade de entregas por mês: </Label>
            <Select
              name="cf_quantidade"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cf_quantidade}>
              {quantity.map((qt) => {
                return (
                  <>
                    <option key={qt.value} value={qt.value}>
                      {qt.label}
                    </option>
                  </>
                );
              })}
            </Select>
            {formik.touched.cf_quantidade && formik.errors.cf_quantidade ? (
              <Error>{formik.errors.cf_quantidade}</Error>
            ) : null}
          </FieldRow>
          <FieldRow>
            {show && status === "success" ? (
              <Success>
                <img src="/Abbiamo_SimboloAzul.png" width="25" /> Obrigado, nós
                entraremos em contato em breve!
              </Success>
            ) : (
              <Button type="submit">Enviar</Button>
            )}
          </FieldRow>
        </form>
      </Wrapper>
    </>
  );
};
export default FormRDStation;
