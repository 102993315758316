import React from "react";
import FormRDStation from "../Form/form.components";
import {
  FormContainer,
  Wrapper,
  TextWrapper,
  FormWrapper,
  Title,
  Description,
  Footer,
  FooterWrapper,
  FooterLink,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  Content,
  Text,
  Icons,
  Link,
} from "./container.styles";

const Container: React.FC = () => {
  return (
    <>
      <FormContainer>
        <Wrapper>
          <TextWrapper>
            <Title>
              Entenda como a nossa solução pode ajudar a sua logística!
            </Title>
            <Description>
              Preencha nosso formulário e em breve entraremos em contato para te
              explicar melhor nossa solução.
            </Description>
          </TextWrapper>
          <FormWrapper>
            <FormRDStation />
          </FormWrapper>
        </Wrapper>
      </FormContainer>
      <Footer>
        <FooterWrapper>
          <Content>
            <Icons>
              <Link>
                <FooterLink
                  href="https://www.facebook.com/abbiamolog"
                  target="_blank">
                  <FacebookIcon />
                </FooterLink>
              </Link>
              <Link>
                <FooterLink
                  href="https://www.instagram.com/abbiamolog/"
                  target="_blank">
                  <InstagramIcon />
                </FooterLink>
              </Link>
              <Link>
                <FooterLink
                  href="https://www.linkedin.com/company/abbiamo-log/"
                  target="_blank">
                  <LinkedinIcon />
                </FooterLink>
              </Link>
            </Icons>
            <Text>©2021 Abbiamo. Todos os direitos reservados.</Text>
          </Content>
        </FooterWrapper>
      </Footer>
    </>
  );
};

export default Container;
